
import { mapActions, mapGetters } from "vuex";
import DeleteEdit from "@/modules/services/components/delete-edit.vue";
import FacilitiesListTable from "@/modules/facilities/components/facilities-list-table.vue";
import FilterFacilities from "@/modules/facilities/components/filter-facilities.vue";
// import {i18n} from '@/i18n';
// import moment from 'moment';
import i18n from '@/vueI18n';

import firebase from "firebase/app";
import "firebase/database";
// import De leteEdit from '@/modules/services/components/delete-edit.vue';
import ImageBackground from "@/modules/home/components/image-background.vue";
import { AnalyticsManager } from '@/shared/firebase/firebase-analytics';

export default {
  data() {
    return {
      filter: false,
      searchFacilities: "",
      facilities: [],
      // addedPropInMonth: 0,
      // canNotAdd: false,
      displayTitle: false,

      currentMonth: undefined,
      currentYear: undefined,
      propertyMaxCount: undefined,
      addedPropertyInMonth: 0,
      userPropertiesInMonth: [],
      userProperty: {},
      canNotAdd: false,

      modalErrorVisible: false,
      dialogErrorVisible: false,

      modalVisible: false,
      dialogVisible: false,

      rowsOldPage: []
    };
  },
  components: {
    [DeleteEdit.name]: DeleteEdit,
    [FacilitiesListTable.name]: FacilitiesListTable,
    [FilterFacilities.name]: FilterFacilities,
    [ImageBackground.name]: ImageBackground,
  },
  methods: {
    i18n(key, args) {
      return this.$t(key, args);
    },
    
    closeFilter() {
      this.filter = !this.filter;
    },
    ...mapActions({
        setHeaderBg: "layout/setHeaderBg",
        setNavbar: "layout/setNavbar",
        setFooter: "layout/setFooter",
        doFetchUser: "facilities/list/doFetchUser",
        setCurrentRoute: "facilities/list/setCurrentRoute",
        doFetchFromRoute: "facilities/list/doFetchFromRoute",
        doFetch: "facilities/list/doFetch",
        setMountedTable: "facilities/list/setMountedTable",
        doFetchFacilityListedFor: "facilities/list/doFetchFacilityListedFor",
        setMountedFacilityListedForTable:
            "facilities/list/setMountedFacilityListedForTable",
        setLoadingAddMoreFacilities: 'facilities/list/setLoadingAddMoreFacilities',
        resetCursor: 'facilities/list/resetCursor',
        setRows: 'facilities/list/setRows'
    }),
    scrollHandler(scroll) {
      if (scroll.position == 0) {
        this.setHeaderBg("bg-transparent");
      } else {
        // this.setHeaderBg('bg-shadow animate__animated animate__fadeInDown')
        this.setHeaderBg("bg-shadow");
      }
    },
    openFilter() {
      this.filter = !this.filter;
    },

    addNewItem() {
      if (this.canNotAdd) {
        this.modalErrorVisible = true;
        this.dialogErrorVisible = true;
      } else {
        this.$router.push("add-facility");
      }
    },
    closeErrorModal() {
      // this.prescriptionDialog = false
      this.dialogErrorVisible = false;
      setTimeout(() => {
        this.modalErrorVisible = false;
      }, 500);
    },
    changeFacilityHeader(value) {
      this.displayTitle = value;
    },
    async addMoreFacilities(userListener) {
      this.setLoadingAddMoreFacilities(true)
      // loadingAddMoreFacilities = true
      this.userProperty = userListener.property;
      this.addedPropertyInMonth = 0;
      var today = new Date();
      if (this.userProperty || this.userProperty != undefined) {
        for (const [key] of Object.entries(this.userProperty)) {
          var firstDay = new Date(today.getFullYear(), today.getMonth(), 1);
          var lastDay = new Date(today.getFullYear(), today.getMonth() + 1, 0);
          if (key >= firstDay && key < lastDay) {
            this.addedPropertyInMonth = this.addedPropertyInMonth + 1;
          }
        }
        await firebase
          .database()
          .ref("setting/property")
          .on("value", (snapshot) => {
            this.propertyMaxCount = snapshot.val();
            if (
              this.addedPropertyInMonth == this.propertyMaxCount ||
              this.addedPropertyInMonth > this.propertyMaxCount
            ) {
              this.canNotAdd = true;
            } else {
              this.canNotAdd = false;
            }
            this.setLoadingAddMoreFacilities(false)
          });
      }
      else{
        this.setLoadingAddMoreFacilities(false)
      }
      
    },
  },
  computed: {

    lang(){
      // debugger    
      return i18n.locale
    },

    ...mapGetters({
      currentUser: "auth/currentUser",
      user: "facilities/list/user",
      userLoading: "auth/userLoading",
      userListener: "auth/userListener",
      loadingAddMoreFacilities: 'facilities/list/loadingAddMoreFacilities',
      detailsLoading: 'facilities/list/detailsLoading'
    }),

    isRTL() {
      return localStorage.getItem("language") == "ar";
    },
    ...mapGetters({
      currentUser: "auth/currentUser",
      mountedFacilityListedfForTable:
        "facilities/list/mountedFacilityListedfForTable",
    }),
    imageBg() {
      return "https://firebasestorage.googleapis.com/v0/b/drug-deal-6e05f.appspot.com/o/public%2Ffacility-img-bg.webp?alt=media&token=126bf432-8230-4f3f-9ac5-e39a6cbc5a88";
    },
    title() {
      return this.i18n("drugDeal.services.sellBuyRent");
    },
    subtitle1() {
      return this.i18n("drugDeal.facilities.youCan");
    },
    subtitle2() {
      return this.i18n("drugDeal.facilities.pharmacy");
    },
    subtitle3() {
      return this.i18n("drugDeal.facilities.oneClick");
    },
    btnDesc() {
      return this.i18n("drugDeal.facilities.add");
    },
    limit() {
      const { screen } = this.$q;
      if (screen.lt.sm) {
        return 4;
      }
      if (screen.lt.md) {
        return 6;
      }
      if (screen.lt.lg) {
        return 9;
      }
      return 9;
    },
  },
  mounted() {
   
  },
  watch: {
    lang(){
      document.title =
        this.i18n('app.title') +
        ' | ' +
        this.i18n('drugDeal.services.facilities') 
    },
    // detailsLoading(newval){
    //   // debugger
    //   console.log('detailsLoading',newval);
    //   // debugger
    // },
    async userListener(newval) {
      if (this.userListener) {
        this.addMoreFacilities(newval);
      }
    },
  },
  async created() {
    document.title =
                this.i18n('app.title') +
                ' | ' +
                this.i18n('drugDeal.services.facilities') 
    this.setFooter(false);
    this.setCurrentRoute(this.$router.currentRoute.name);
    if (this.userListener) {
      this.addMoreFacilities(this.userListener);
    }

    AnalyticsManager.logScreen('View Facility Details', 'FacilityProfile');

  },
  destroyed(){
    this.setLoadingAddMoreFacilities(true)
    this.setMountedTable(false);
    this.resetCursor();
  }

};
